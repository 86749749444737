import * as React from 'react'
import { Box, Button, Container, Flex, Image, Text } from '@chakra-ui/react'
import { forwardRef } from 'react'
import useAvatar from '../helpers/useAvatar'
import { StaticImage } from 'gatsby-plugin-image'

const BookingListItem = forwardRef(({ item, onClick }, ref) => {
	const { nickname, name } = item
	let avatar = useAvatar(nickname || name)?.default

	const styles = {
		groupMemberBoard: {
			imgSize: '46px',
			pSize: '8px',
			pGap: '2px',
			gap: '10px',
			ptGap: '3px',
			container: 'leaderboard',
			rankVariant: 'rank',
			nameVariant: 'ldName',
			scoreVariant: 'score'
		}
	}

	const { container, imgSize, gap, nameVariant } = styles['groupMemberBoard']

	if (item.type === 'awaiting') {
		return (
			<Container
				onClick={onClick}
				ref={ref}
				variant={container}
				bg={'rgba(255, 255, 255, 0.2)'}
			>
				<Flex w={imgSize}>
					{avatar && (
						<Box
							style={{
								width: imgSize,
								height: imgSize,
								borderRadius: imgSize,
								background: '#C8C8C899'
							}}
						/>
					)}
				</Flex>
				<Flex flex="1" direction="column" overflow="hidden" mx={gap}>
					<Text
						variant={nameVariant}
						color={'#C8C8C899'}
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
					>
						Waiting for player
					</Text>
				</Flex>
			</Container>
		)
	}

	return (
		<Container
			onClick={onClick}
			ref={ref}
			variant={container}
			bg={'rgba(255, 255, 255, 0.2)'}
		>
			<Flex w={imgSize}>
				{avatar && (
					<Image
						alt="Avatar"
						style={{
							width: imgSize,
							height: imgSize,
							borderRadius: imgSize
						}}
						src={avatar}
					/>
				)}
			</Flex>
			<Flex flex="1" direction="column" overflow="hidden" mx={gap}>
				<Text
					variant={nameVariant}
					color={'white'}
					overflow="hidden"
					textOverflow="ellipsis"
					whiteSpace="nowrap"
				>
					{nickname || name || ''}
				</Text>
			</Flex>

			{item.rfid && (
				<StaticImage
					style={{
						height: 35,
						width: 35
					}}
					alt="ok"
					src="../images/icon-connect.png"
				/>
			)}
		</Container>
	)
})

export default BookingListItem
