import * as React from 'react'
import { Container, Flex, Button, Text, Box, Image } from '@chakra-ui/react'
import CloseSVG from '../icons/close.svg'
import Avatar from '../images/avatars/1.png'
import { StaticImage } from 'gatsby-plugin-image'
import { getImageById } from '../helpers'
import useAvatar from '../helpers/useAvatar'
import Note from './note'

const Modal = (props) => {
	const {
		opened,
		onClose,
		children,
		primaryBtn,
		secondaryBtn,
		title,
		description,
		group,
		member,
		isPink,
		offsetLeft = true
	} = props
	const memberAvatar = useAvatar(member?.nickname || member?.name)

	return (
		<Container
			variant="modal"
			display={!!opened ? 'flex' : 'none'}
			w="100%"
		>
			<Flex direction="column" alignItems="center" width="100%">
				<Flex
					direction="column"
					w="100%"
					maxW={{ md: '400px', base: '100vw' }}
					px="0"
					ml={{ lg: '0', base: '0' }}
					position="relative"
					width="100%"
					pt="48px"
				>
					{/* <Button
						alignSelf="flex-end"
						variant="icon"
						onClick={onClose}
					>
						<CloseSVG />
					</Button> */}
					<Container variant="modalContent" zIndex="3" width="100%">
						{!!title && (
							<Text
								as="h1"
								variant="xls"
								mt="40px"
								mb="20px"
								px="0"
								textAlign="center"
							>
								{title}
							</Text>
						)}
						{!!description && (
							<Text
								variant="m"
								px="20px"
								textAlign="center"
								mb="20px"
							>
								{description}
							</Text>
						)}
						<Flex
							direction="column"
							justifyContent="center"
							alignItems="center"
							w="100%"
						>
							{children}
							{!!member && (
								<Flex
									direction="column"
									justifyContent="center"
									alignItems="center"
									width="100%"
								>
									<Image
										src={
											member.teamAvatar ||
											(member.nickname
												? memberAvatar.default
												: Avatar)
										}
										mb="6px"
										width="90px"
										height="90px"
										alt="Team member icon"
									/>
									<Text
										as="p"
										variant="lg"
										w="100%"
										overflow="hidden"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
										textAlign="center"
									>
										{member.nickname || member.name || ''}
									</Text>
								</Flex>
							)}
							{!!group && group?.name && (
								<>
									{/* <Image
										mb="6px"
										src={
											!!team.avatar
												? getImageById(team.avatar)
												: Avatar
										}
										width="90px"
										height="90px"
										alt="Team icon"
									/> */}
									<Note
										text={group.name}
										backgroundOpacity={0.25}
										color="black"
									/>
									{/* <Text
										as="h1"
										mt="20px"
										textAlign="center"
										variant="xl"
										w="100%"
										// overflow="hidden"
										// textOverflow="ellipsis"
										// whiteSpace="nowrap"
									></Text> */}
									<Text
										variant="m"
										px="20px"
										textAlign="center"
										mt="20px"
									>
										See you soon!
									</Text>
								</>
							)}
						</Flex>
					</Container>
					{(!!primaryBtn || !!secondaryBtn) && (
						<Flex
							width="100%"
							justifyContent="center"
							direction="column"
							px="20px"
							mb="30px"
							zIndex="2"
						>
							{primaryBtn}
							{secondaryBtn}
						</Flex>
					)}
					<Box
						position="absolute"
						maxH={{
							lg: 'calc(100vh - 200px)',
							base: 'calc(100vh)'
						}}
						top="55px"
						bottom="0px"
						right="0px"
						left="0px"
						zIndex="1"
					>
						{!!isPink ? (
							<StaticImage
								alt="Modal Background"
								style={{ width: '100%', height: '100%' }}
								src={`../images/pink-modal.png`}
							/>
						) : (
							<StaticImage
								alt="Modal Background"
								style={{ width: '100%', height: '100%' }}
								src={`../images/light-modal.png`}
							/>
						)}
					</Box>
				</Flex>
			</Flex>
		</Container>
	)
}

export default Modal
