import { get, post } from './client'

const getBooking = async (bookingId) => {
	return await get(`bookings/${bookingId}`, {
		token: process.env.REACT_APP_BOOKING_API_TOKEN
	})
}

const getMembers = async (bookingId) => {
	return await get(`bookings/${bookingId}/members`, {
		token: process.env.REACT_APP_BOOKING_API_TOKEN
	})
}

const addNewMember = async (bookingId, nickname) => {
	return await post(`bookings/${bookingId}/members`, {
		token: process.env.REACT_APP_BOOKING_API_TOKEN,
		body: [
			{
				nickname
			}
		]
	})
}

const updateMember = async (bookingId, memberId, nickname) => {
	return await post(`bookings/${bookingId}/members`, {
		token: process.env.REACT_APP_BOOKING_API_TOKEN,
		body: [
			{
				uid: memberId,
				nickname
			}
		]
	})
}

const assignRfidToMember = async (bookingId, memberId, rfid) => {
	return await post(`bookings/${bookingId}/members/${memberId}`, {
		token: process.env.REACT_APP_BOOKING_API_TOKEN,
		body: {
			rfid
		}
	})
}

export {
	getBooking,
	getMembers,
	addNewMember,
	updateMember,
	assignRfidToMember
}
